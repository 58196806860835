<template>
  <div class="login">
    <div class="signup-body">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="p-5 text-center col-md-6 col-lg-5">
            <span v-if="verifyResp">
              <h5 class="orange-text bold">{{ verifyResp.title }}</h5>
              <p>{{ verifyResp.message }}</p>
              <el-button type="success" @click="$router.push(buttonUrl)">{{ buttonText }}</el-button>
            </span>
            
            <span v-if="verifyErr">
              <h5 class="orange-text bold">{{ verifyErr.title }}</h5>
              <el-button type="success" class="mt-3">Re-send verification link</el-button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/vuex/store";
import {userFeedPath} from '@/vuex/utils/authentication';
import {mapActions, mapGetters} from "vuex";

export default {
  methods : {
    ...mapActions({
      verifyEmail: 'verifyEmail',
    }),
  },

  computed : {
    buttonText() {
      if (store.getters.isAuthenticated) {
        return 'Continue to your dashboard';
      } else {
        return 'Log-in';
      }
    },

    buttonUrl() {
      if (store.getters.isAuthenticated) {
        return userFeedPath();
      } else {
        return '/login';
      }
    },

    ...mapGetters({
      verifyResp : 'getVerifyEmailResponse',
      verifyErr : 'getVerifyEmailError'
    })
  },

  mounted() {
    if (this.$route.query.t) {
      this.verifyEmail(this.$route.query.t);
    }
  },
}
</script>
